import React from 'react';
import {PriceItemTwoLiner} from '../PricingComponents';
import {useI18next} from 'gatsby-plugin-react-i18next';

const EmailsPerMonth: React.FC<{value?: number; disabled?: boolean}> = ({
  value = Number.POSITIVE_INFINITY,
  disabled = false
}) => {
  const {t} = useI18next();
  return (
    <PriceItemTwoLiner disabled={disabled}>
      <b>{value}</b> {t('emails per month')}
    </PriceItemTwoLiner>
  );
};

export default EmailsPerMonth;
