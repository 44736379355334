import React from 'react';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {
  Price,
  PriceHeader,
  PriceIcon,
  PriceAmount,
  PriceTitle,
  PriceSubtitle,
  PriceDescription,
  PriceList,
  PriceItemThreeLiner,
  PriceTitleText
} from './PricingComponents';
import Button from 'components/Button';
import {links} from 'global.config';
import Enterprise from 'images/enterprise-plan.svg';
import StoreContacts from './PricingItems/StoreContacts';
import ActiveContacts from './PricingItems/ActiveContacts';
import EmailsPerMonth from './PricingItems/EmailsPerMonth';
import EventsPerMonth from './PricingItems/EventsPerMonth';
import GraphqlApi from './PricingItems/GraphqlApi';
import DomainsComponent from './PricingItems/Domains';
import DedicatedIpComponent from './PricingItems/DedicatedIp';
import Extras from 'components/Pricing/extras';

const EnterprisePrice: React.FC = () => {
  const {t} = useI18next();
  return (
    <Price narrow>
      <PriceHeader>
        <PriceIcon
          src={Enterprise}
          alt="OmniChannel Communication Platform run by an Austronaut"
          width="175"
          height="42"
        />
        <PriceTitle>
          <PriceTitleText>Enterprise</PriceTitleText>
        </PriceTitle>
        <PriceAmount>{t('Get In Touch')}</PriceAmount>
        <PriceSubtitle>{t('For high volume plans')}</PriceSubtitle>
      </PriceHeader>
      <PriceDescription>
        <PriceList>
          <StoreContacts />
          <ActiveContacts />
          <EmailsPerMonth />
          <EventsPerMonth />
          <DomainsComponent />
          <DedicatedIpComponent />
          <GraphqlApi />
          <Extras />
          <PriceItemThreeLiner>
            <Trans>
              Get in touch to know <br /> additional contacts fee
            </Trans>
          </PriceItemThreeLiner>
          <PriceItemThreeLiner>
            <Trans>
              Get in touch to know <br /> additional contacts fee
            </Trans>
          </PriceItemThreeLiner>
          <PriceItemThreeLiner>
            <Trans>
              Get in touch to know <br /> additional contacts fee
            </Trans>
          </PriceItemThreeLiner>
        </PriceList>
        <Button
          dark={true}
          href={links.alex}
          title={t('OmniChannel Communication Platform to Float now')}>
          {t('Contact Sales')}
        </Button>
      </PriceDescription>
    </Price>
  );
};

export default EnterprisePrice;
