import React from 'react';
import {PriceItemTwoLiner} from '../PricingComponents';
import {useI18next} from 'gatsby-plugin-react-i18next';

const Domains: React.FC<{value?: number}> = ({value = Number.POSITIVE_INFINITY}) => {
  const {t} = useI18next();
  return (
    <PriceItemTwoLiner>
      <b>{value}</b> {t('domain(s)')}
    </PriceItemTwoLiner>
  );
};

export default Domains;
