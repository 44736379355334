import React from 'react';
import styled from 'styled-components';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {media} from 'global.config';

const mainColor = '#0caa48';

const FrequencyButton = styled.button<{selected: boolean}>`
  color: ${props => (props.selected ? '#fff' : mainColor)};
  background-color: ${props => (props.selected ? mainColor : 'inherit')};
  border: 1px solid ${mainColor};
  transition: background-color 0.25s, color 0.25s;
  height: 43px;
  line-height: 1;
  font-size: 18px;
  min-width: 180px;
  text-align: center;
  border-radius: 7px;
  padding-left: 24px;
  padding-right: 24px;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  @media ${media.mobile} {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 18px;
  }
`;

const FrequencyLeftButton = styled(FrequencyButton)`
  border-right: none;
  border-bottom-right-radius: initial;
  border-top-right-radius: initial;
  position: absolute;
  right: 50%;
`;

const FrequencyRightButton = styled(FrequencyButton)`
  border-left: none;
  border-bottom-left-radius: initial;
  border-top-left-radius: initial;
`;

const Container = styled.div`
  padding: 24px 2% 48px;
  position: relative;
`;

const SaveMoreYearly = styled.span`
  font-weight: 600;
  margin-left: 24px;
  &::before {
    content: '\u2190';
    margin-right: 4px;
  }
  @media ${media.mobile} {
    position: absolute;
    left: -50%;
    top: 45px;
    &::after {
      content: '\u2197';
      position: relative;
      top: -5px;
    }

    &::before {
      content: '';
    }
  }
`;

const YearlyContainer = styled.span`
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
`;

const BillingFrequencySelector: React.FC<{onChange: Function; frequency: string}> = ({
  onChange,
  frequency
}) => {
  const {t} = useI18next();
  return (
    <Container>
      <FrequencyLeftButton selected={frequency === 'month'} onClick={() => onChange('month')}>
        {t('Pay monthly')}
      </FrequencyLeftButton>
      <YearlyContainer>
        <FrequencyRightButton selected={frequency === 'year'} onClick={() => onChange('year')}>
          {t('Pay yearly')}
        </FrequencyRightButton>
        <SaveMoreYearly>Save up to 25%! </SaveMoreYearly>
      </YearlyContainer>
    </Container>
  );
};

export default BillingFrequencySelector;
