import React from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';

import {PriceItemTwoLiner} from '../PricingComponents';

const StoreContacts: React.FC = () => {
  return (
    <PriceItemTwoLiner>
      <Trans>
        store <b>unlimited</b> contacts
      </Trans>
    </PriceItemTwoLiner>
  );
};

export default StoreContacts;
