import React from 'react';
import {PriceItemTwoLiner} from '../PricingComponents';
import {useI18next} from 'gatsby-plugin-react-i18next';

const GraphqlApi: React.FC<{disabled?: boolean}> = ({disabled = false}) => {
  const {t} = useI18next();
  return <PriceItemTwoLiner disabled={disabled}>{t('GraphQL API')}</PriceItemTwoLiner>;
};

export default GraphqlApi;
