import React from 'react';
import styled from 'styled-components';
import {Trans} from 'gatsby-plugin-react-i18next';

const CenteredText = styled.p`
  text-align: center;
`;

export default () => {
  return (
    <CenteredText>
      <Trans i18nKey="refund policy">
        {'We offer a '}
        <em>30-day Refund Policy</em>
        {" if you're not happy. Click "}
        <a target="_blank" href="https://support.moonmail.io/article/121-moonmail-pricing">
          here
        </a>
        {' to read our full Pricing Policy. And '}
        <a
          target="_blank"
          href="https://support.moonmail.io/article/227-moonmails-pricing-policy-pay-what-feels-good">
          here
        </a>
        {" to read MoonMail's Pay What Feels Good Policy."}
      </Trans>
    </CenteredText>
  );
};
