import React from 'react';
import styled from 'styled-components';
import {useI18next, Helmet} from 'gatsby-plugin-react-i18next';
import {styles, media} from 'global.config';
import Stars from 'components/Stars';
import CurvedBorder from 'components/CurvedBorder';
import Pricing from 'components/Pricing';
import {PricingPlan} from 'components/Pricing/PricingPlanInterface';
import PricingImage from 'images/pricing.svg';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 80px;
  position: relative;
  min-height: calc(100vh - 170px);
  margin: auto;
  max-width: ${styles.containerWidth};

  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 235px;
    margin-bottom: 20px;
  }
`;

const PricingPage: React.FC<{pricingPlans: PricingPlan[]}> = ({pricingPlans}) => {
  const {t} = useI18next();
  return (
    <>
      <Helmet title={t('MoonMail Pricing')}>
        <meta
          name="description"
          content={t(
            'Get the deepest insight of all the pricing plans available for your MoonMail account. Click here now!'
          )}
        />
      </Helmet>
      <div className="index-page">
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={PricingImage}
                alt={t('MoonMail Pricing')}
                title={t('MoonMail Pricing')}
                width="303"
                height="201"
              />
              <h1>{t("Hope You've Got a Plan!")}</h1>
            </IndexSectionContainer>
            <CurvedBorder />
          </IndexSection>
        </Stars>
        <Pricing plans={pricingPlans} />
      </div>
    </>
  );
};

export default PricingPage;
