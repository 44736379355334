import React, {useState} from 'react';
import {useI18next, Link} from 'gatsby-plugin-react-i18next';
import {PricesContainer, CenteredText, PricesSection} from './PricingComponents';
import Button from 'components/Button';
import {links} from 'global.config';
import RefundPolicy from './RefundPolicy';
import Enterprise from './Enterprise';
import Hint from './Hint';
import {PricingPlan} from './PricingPlanInterface';
import PriceColumn from './PriceColumn';
import BillingFrequencySelector from './BillingFrequencySelector';

const findPlan = (plans: PricingPlan[], name: string, interval: string): PricingPlan | null =>
  plans.find(plan => plan.Interval === interval && plan.Name === name) || null;

const Pricing: React.FC<{forMarketing?: boolean; plans: PricingPlan[]}> = ({
  forMarketing = false,
  plans
}) => {
  const {t} = useI18next();
  const [billingFrequency, setBillingFrequency] = useState('year');
  const litePlan = findPlan(plans, 'Lite', billingFrequency);
  const professionalPlan = findPlan(plans, 'Professional', billingFrequency);
  const starterPlan = findPlan(plans, 'Starter', billingFrequency);
  return (
    <>
      {!forMarketing && (
        <BillingFrequencySelector onChange={setBillingFrequency} frequency={billingFrequency} />
      )}
      <PricesSection>
        <PricesContainer narrow={!forMarketing}>
          {litePlan && (
            <PriceColumn
              plan={litePlan}
              forMarketing={forMarketing}
              showExtras={true}
              action={
                <Button
                  dark={true}
                  href={links.signUp}
                  title={t('OmniChannel Communication Platform to Float now')}>
                  {t('Start Now')}
                </Button>
              }
            />
          )}
          {starterPlan && (
            <PriceColumn
              plan={starterPlan}
              forMarketing={forMarketing}
              showExtras={true}
              action={
                <Button
                  href={links.signUp}
                  title={t('OmniChannel Communication Platform to Float now')}>
                  {t('Start Now')}
                </Button>
              }
            />
          )}
          {!forMarketing && professionalPlan && (
            <PriceColumn
              plan={professionalPlan}
              forMarketing={forMarketing}
              showExtras={true}
              action={
                <Button
                  href={links.signUp}
                  title={t('OmniChannel Communication Platform to Float now')}>
                  {t('Start Now')}
                </Button>
              }
            />
          )}
          {!forMarketing && <Enterprise />}
          <Hint long={forMarketing} />
          <RefundPolicy />
          {forMarketing && (
            <CenteredText>
              <Link to="/pricing">{t('See detailed pricing')}</Link>
            </CenteredText>
          )}
        </PricesContainer>
      </PricesSection>
    </>
  );
};

export default Pricing;
