import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {styles, media, colors} from 'global.config';
import Container from 'components/Container';
import PriceBackground from 'images/price-bg.png';

export const PricesSection = styled.section``;

export const CenteredText = styled.p`
  text-align: center;
`;

export const SecondaryText = styled.div`
  opacity: 0.5;
`;

export const PricesContainer = styled(Container)<{narrow?: boolean}>`
  ${props => props.narrow && 'max-width: none;'}
  padding: 70px ${props => (props.narrow ? '2%' : styles.containerPadding)} 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  @media ${media.desktop} {
    max-width: 720px;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10%;
    right: 10%;
    border-bottom: 1px solid #e5e5e5;
  }
  @media ${media.mobile} {
    display: block;
    padding-bottom: 50px;
    &:after {
      display: none;
    }
  }
`;

export const Price = styled.div<{narrow?: boolean}>`
  width: ${props => (props.narrow ? 320 : 370)}px;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: ${props => (props.narrow ? 30 : 0)}px;
  margin-left: ${props => (props.narrow ? 3 : 20)}px;
  margin-right: ${props => (props.narrow ? 3 : 20)}px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  @media ${media.mobile} {
    width: auto;
    margin: 0 10px 30px;
  }
`;
export const PriceHeader = styled.div`
  position: relative;
  padding: 30px 20px;
  height: 220px;
  text-align: center;
  color: #fff;
  background: url(${PriceBackground}) center center no-repeat;
  background-size: cover;
`;
export const PriceIcon = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
`;
export const PriceTitle = styled.div`
  margin: 33px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PriceTitleText = styled.span`
  font-size: 52px;
  line-height: 52px;
`;
export const PriceAmount = styled.h3`
  margin: 0;
  line-height: 40px;
  font-weight: 500;
  font-size: 19px;
`;
export const PriceSubtitle = styled.p`
  margin: 0;
`;
export const PriceDescription = styled.div`
  padding: 10px 0 35px;
  text-align: center;
`;
export const PriceList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  line-height: 20px;
  li {
    position: relative;
    padding: 10px 25px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #ffffff;
      background: linear-gradient(to right, #ffffff 0%, #ebebeb 50%, #ffffff 100%);
    }
  }
`;

export const PriceHint = styled.div`
  margin-top: 20px;
  color: #afafaf;
  font-size: 14px;
  line-height: 18px;
  flex: 1;
  min-width: 100%;
  text-align: center;
`;

const PriceItemContainer = styled.li<{disabled?: boolean}>`
  color: ${props => (props.disabled ? '#d5d5d5' : 'inherit')};
  text-decoration: ${props => (props.disabled ? 'line-through' : 'inherit')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PriceItem: React.FC<{disabled?: boolean; children: ReactNode; className?: string}> = ({
  disabled,
  children,
  className
}) => (
  <PriceItemContainer disabled={disabled} className={className}>
    <span>{children}</span>
  </PriceItemContainer>
);

export const PriceItemTwoLiner = styled(PriceItem)`
  height: 60px;
`;

export const PriceItemThreeLiner = styled(PriceItem)`
  height: 80px;
`;
