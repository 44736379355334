import React from 'react';
import {AnchorLink} from 'components/AnchorLink';
import {Trans} from 'gatsby-plugin-react-i18next';

export default () => {
  const h = `\u2764`;
  return (
    <>
      <Trans parent="li">
        No branding in email footer. We let you spread some love though {{h}}
      </Trans>
      <Trans parent="li">Channel: email</Trans>
      <Trans parent="li">
        <AnchorLink
          external
          href="https://amp.dev/documentation/guides-and-tutorials/learn/email-spec/amp-email-format/?format=email"
          target="_blank">
          AMP Support
        </AnchorLink>
      </Trans>
      <Trans parent="li">Send time optimization</Trans>
      <Trans parent="li">Throughput with SLA up to 10 million emails per hour</Trans>
      <Trans parent="li">AI powered engaged recipients filter</Trans>
      <Trans parent="li">Inteligent send time optimization</Trans>
      <Trans parent="li">Recipient validation pre sending</Trans>
      <Trans parent="li">Inbox placement shield</Trans>
      <Trans parent="li">IP farm spread</Trans>
      <Trans parent="li">Deep logs & advanced analytics</Trans>
      <Trans parent="li">Use Any Email as the Send Address</Trans>
      <Trans parent="li">A/B testing</Trans>
      <Trans parent="li">Posh Support (24/7 Ticket)</Trans>
      <Trans parent="li">Web Forms with Machine Learning powered Dynamic Email Validation</Trans>
      <Trans parent="li">Automatic Management of Bounces, Complaints and Spam Reports</Trans>
      <Trans parent="li">Custom configuration of DKIM & SPF</Trans>
      <Trans parent="li">Event based Segmentation</Trans>
      <Trans parent="li">Advanced segmentation</Trans>
      <Trans parent="li">Fully GDPR Compliant</Trans>
      <Trans parent="li">Event based Automations</Trans>
      <Trans parent="li">Custom events</Trans>
      <Trans parent="li">Sender reputation management and monitoring</Trans>
      <Trans parent="li">Dedicated IP</Trans>
      <Trans parent="li">Dedicated IP subnets</Trans>
      <Trans parent="li">Custom rDNS</Trans>
      <Trans parent="li">Insightful IP warm-up</Trans>
      <Trans parent="li">Spam Trap & Blacklist Monitoring</Trans>
      <Trans parent="li">Custom removal of flags within blacklist providers</Trans>
      <Trans parent="li">Access to Extensions & Integrations</Trans>
      <Trans parent="li">Adoring drag n drop editor</Trans>
      <Trans parent="li">SMTP access for easy transactional email sending</Trans>
      <Trans parent="li">Maximum server computational throughput allocation for API access</Trans>
      <Trans parent="li">Dynamic inclusion of email headers***</Trans>
      <Trans parent="li">
        Choose the transport you want to use: SES, SparkPost, SendGrid, MailGun
      </Trans>
      <Trans parent="li">Subscribe through AWS SAAS marketplace</Trans>
      <Trans parent="li">Email Tracking and Analytics</Trans>
      <Trans parent="li">99.99% Guaranteed Uptime SLA</Trans>
      <Trans parent="li">Detailed Documentation</Trans>
      <Trans parent="li">Channel: SMS</Trans>
      <Trans parent="li">Channel: WhatsApp</Trans>
      <Trans parent="li">Channel: Voice</Trans>
      <Trans parent="li">Channel: Push</Trans>
    </>
  );
};
