import React from 'react';
import {PriceItemTwoLiner} from '../PricingComponents';
import {useI18next} from 'gatsby-plugin-react-i18next';

const DedicatedIp: React.FC<{disabled?: boolean}> = ({disabled = false}) => {
  const {t} = useI18next();
  return <PriceItemTwoLiner>{t('Dedicated IP')}</PriceItemTwoLiner>;
};

export default DedicatedIp;
