import React from 'react';
import {PriceHint} from './PricingComponents';
import {useI18next} from 'gatsby-plugin-react-i18next';

const Hint: React.FC<{long?: boolean}> = ({long = false}) => {
  const {t} = useI18next();
  return (
    <PriceHint>
      <>
        {long && (
          <>
            {'* '}
            {t('If paid annually.')}
            <br />
          </>
        )}
        {long ? '** ' : '* '}
        {t(
          'Active contacts: The total number of individual customers / users that you contact in a 30-day period.'
        )}
        <br />
      </>
      {long && (
        <>
          {'*** '}
          {t(
            'For better performance like List unsubscribe and specific call to actions for ecommerce, travel, hotel & SAAS customer engagement.'
          )}
        </>
      )}
    </PriceHint>
  );
};

export default Hint;
