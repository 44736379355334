import React, {ReactNode} from 'react';
import {useI18next} from 'gatsby-plugin-react-i18next';
import Dinero from 'dinero.js';
import {
  Price,
  PriceHeader,
  PriceIcon,
  PriceAmount,
  PriceTitleText,
  PriceTitle,
  PriceSubtitle,
  PriceDescription,
  PriceList
} from './PricingComponents';
import Extras from './extras';
import Lite from 'images/astronaut.svg';
import Starter from 'images/rocket.svg';
import Professional from 'images/pro-plan.svg';
import Enterprise from 'images/enterprise-plan.svg';
import StoreContacts from './PricingItems/StoreContacts';
import ActiveContacts from './PricingItems/ActiveContacts';
import EmailsPerMonth from './PricingItems/EmailsPerMonth';
import EventsPerMonth from './PricingItems/EventsPerMonth';
import DomainsComponent from './PricingItems/Domains';
import DedicatedIpComponent from './PricingItems/DedicatedIp';
import GraphqlApi from './PricingItems/GraphqlApi';
import AdditionalContactsFee from './PricingItems/AdditionalContactsFee';
import AdditionalEmailsFee from './PricingItems/AdditionalEmailsFee';
import AdditionalEventsFee from './PricingItems/AdditionalEventsFee';
import {PricingPlan} from './PricingPlanInterface';

interface PriceColumnProps {
  forMarketing?: boolean;
  showExtras?: boolean;
  plan?: PricingPlan;
  action: ReactNode;
}

const PriceColumn: React.FC<PriceColumnProps> = ({
  plan = {},
  forMarketing = false,
  action,
  showExtras = false
}) => {
  const images = {Lite, Starter, Professional, Enterprise};
  const {
    Name,
    Currency,
    Amount = 0,
    TrialPeriodDays,
    PrepaidEvents,
    PrepaidEmails,
    PrepaidContacts,
    EmailsUsageFee,
    EventsUsageFee,
    ContactsUsageFee,
    Domains,
    DedicatedIp,
    ApiAccess,
    Interval
  } = plan;
  // @ts-ignore
  const illustration: string = images[Name];
  const fee = Interval === 'month' ? Amount : Math.ceil(Amount / 12);
  const {t} = useI18next();
  return (
    <Price narrow={!forMarketing}>
      <PriceHeader>
        <PriceIcon
          src={illustration}
          alt={t('OmniChannel Communication Platform')}
          width="55"
          height="58"
        />
        <PriceTitle>
          <PriceTitleText>{Name}</PriceTitleText>
        </PriceTitle>
        {Amount && (
          <PriceAmount>
            {Dinero({amount: fee * 100, currency: Currency}).toFormat()}
            {' / '}
            {t('month')}
            {forMarketing && '*'}
          </PriceAmount>
        )}
      </PriceHeader>
      <PriceDescription>
        <PriceList>
          <StoreContacts />
          <ActiveContacts value={PrepaidContacts} hintStars={forMarketing ? 2 : 1} />
          <EmailsPerMonth value={PrepaidEmails} />
          <EventsPerMonth value={PrepaidEvents} />
          <DomainsComponent value={Domains} />
          <DedicatedIpComponent disabled={!DedicatedIp} />
          <GraphqlApi disabled={!ApiAccess} />
          {showExtras && <Extras />}
          <AdditionalContactsFee value={ContactsUsageFee} />
          <AdditionalEmailsFee value={EmailsUsageFee} />
          <AdditionalEventsFee value={EventsUsageFee} />
        </PriceList>
        {action}
      </PriceDescription>
    </Price>
  );
};

export default PriceColumn;
