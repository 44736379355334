import React from 'react';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {PriceItemTwoLiner} from '../PricingComponents';

const ActiveContacts: React.FC<{value?: number; hintStars?: number}> = ({
  value = Number.POSITIVE_INFINITY,
  hintStars = 2
}) => {
  const {t} = useI18next();
  return (
    <PriceItemTwoLiner>
      <b>{value}</b> {t(' active contacts ')}
      {hintStars === 1 ? '*' : '**'}
    </PriceItemTwoLiner>
  );
};

export default ActiveContacts;
