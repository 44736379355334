import React from 'react';
import Dinero from 'dinero.js';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {PriceItemThreeLiner, SecondaryText} from '../PricingComponents';

const AdditionalContactsFee: React.FC<{value?: number; currency?: string; disabled?: boolean}> = ({
  value = Number.POSITIVE_INFINITY,
  currency = 'usd',
  disabled = false
}) => {
  const {t} = useI18next();
  return (
    <PriceItemThreeLiner>
      <b>{Dinero({amount: value * 100, currency}).toFormat()}</b> {t('additional contact fee')}
      <br />
      <SecondaryText>{t('per 1000 contacts')}</SecondaryText>
    </PriceItemThreeLiner>
  );
};

export default AdditionalContactsFee;
